import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerPrimary from "../components/banners/bannerPrimary"
import { Section, Container, Flex } from "../components/layoutComponents"
import FullFaq from "../components/faqs/fullFaq"
import AppointmentInfo from "../components/appointmentInfo"

export default function Faqs() {
  return (
    <Layout>
      <SEO
        title="FAQs"
        description="Get the answers to your questions from a registered massage therapist in Calgary!"
      />
      <BannerPrimary
        to1="/"
        link1="Home"
        to2="/faqs"
        link2="Faqs"
        title="frequently asked questions"
        description="If you are new to massage, we understand you might have some quesstions. We are confident that we have answers to all your questions on this page. Should you have additional questions, please feel free to contact us."
      />
      <Section>
        <Container>
          <Flex>
            <div className="spacing">
              <h1 className="title--bold italics accent">
                have burning questions about massage therapy?
              </h1>
              <p>
                If you are new to massage, we understand you might have some
                quesstions. We are confident that we have answers to all your
                questions on this page. Should you have additional questions,
                please feel free to contact us.
              </p>
            </div>
            <StaticImage
              src="../images/massage-faq.jpg"
              alt="massage therapy faq"
              className="stretch"
            />
          </Flex>
        </Container>
      </Section>

      <FullFaq />
      <AppointmentInfo />
    </Layout>
  )
}
